<template>
  <div id="ComponentePessoaForm" class="p-2 card">
    <h2 class="mb-3">Cadastro de Ciclos</h2>
    <b-overlay :show="loading.pagina" rounded="lg" opacity="0.7">
      <validation-observer ref="simpleRules">
        <b-form id="CicloForm" ref="CicloForm" @submit.prevent="submitForm()">
          <div class="GrupoEmpresa" id="Empresa">
            <b-row>
              <b-col md="8" class="mb-1">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Grupo*"
                    label-cols-lg="2"
                    label-for="Select-Grupo"
                  >
                    <v-select
                      id="Select-Grupo"
                      v-model="form.grupo.selecionado"
                      @input="selecionaGrupo"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_empresa_grupo"
                      label="descricao"
                      :options="form.grupo.dadosSelect"
                      placeholder="Selecione a Grupo"
                      :disabled="form.grupo.disabled"
                    >
                      <span slot="no-options">Nenhum Grupo encontrado.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <b-button
                  type="confirm"
                  :disabled="form.grupo.disabledButton"
                  variant="outline-primary"
                  class="mr-1"
                  @click.prevent="setGrupo"
                >
                  Selecionar
                </b-button>
                <b-badge :variant="getClasseSituacao(form.status)">{{
                  form.status
                }}</b-badge>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8" class="mb-1">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Empresa*"
                    label-cols-lg="2"
                    label-for="Select-Filial"
                  >
                    <v-select
                      id="Select-Filial"
                      v-model="form.filiais.selecionado"
                      @input="selecionaEmpresa"
                      variant="custom"
                      item-text="nomeDescricao"
                      item-value="id_empresa"
                      label="nomeDescricao"
                      :options="form.filiais.dadosSelect"
                      placeholder="Selecione a Empresa"
                      :disabled="form.filiais.disabled"
                    >
                      <span slot="no-options">Nenhuma Empresa encontrada.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <b-button
                  type="reset"
                  :disabled="form.filiais.disabledButton"
                  variant="outline-primary"
                  class="mr-1"
                  @click.prevent="setEmpresa"
                >
                  Selecionar
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div class="parametros" id="parametros">
            <b-row>
              <b-col md="4" class="mb-1">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Produto*"
                    label-for="Select-Modulos"
                  >
                    <v-select
                      id="Select-Modulos"
                      v-model="form.modulos.selecionado"
                      @input="setModulo"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_modulo"
                      label="descricao"
                      :options="form.modulos.dadosSelect"
                      placeholder="Selecione um Produto"
                      :disabled="form.modulos.disabled"
                    >
                      <span slot="no-options">Nenhum módulo encontrado.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Questionários*"
                    label-for="Select-Questionarios"
                  >
                    <v-select
                      id="Select-Modulos"
                      v-model="form.questionario.selecionado"
                      @input="setQuestionario"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_questionario"
                      label="descricao"
                      :options="form.questionario.dadosSelect"
                      placeholder="Selecione um Questionário"
                      :disabled="form.questionario.disabled"
                    >
                      <span slot="no-options"
                        >Nenhum questionário encontrado.</span
                      >
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <validation-provider
                  name="Data Início"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Data de Início*"
                    label-for="dataInicio"
                  >
                    <b-input-group
                      class="tamanhoInputDate"
                    >
                      <b-form-input
                        id="dataInicio"
                        type="text"
                        :state="errors.length > 0 || formErrosApi.dataInicio || formErrosDataHora.dataInicio ? false : null"
                        v-model="form.dataInicio.input"
                        v-mask="'##/##/####'"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        :disabled="form.dataInicio.disabled"
                        @input="verificaDataHoraValida(form.dataInicio.input, 'DD/MM/YYYY', 'dataInicio')"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          class="form-control d-flex align-items-center"
                          :style="errors.length > 0 || formErrosApi.dataInicio || formErrosDataHora.dataInicio ? 'border: 1px solid red' : null"
                          button-only
                          right
                          v-model="form.dataInicio.picker"
                          v-bind="labels[locale]"
                          :locale="locale"
                          :disabled="form.dataInicio.disabled"
                          button-variant="custom-blue"
                          :hide-header="true"
                          clearable
                          @input="setDataPicker('dataInicio')"
                          :label-help="'Use as setas do teclado para navegar'"
                          :min="dataHoje"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors.length > 0" class="text-danger">
                      {{ errors[0] }}
                    </small>
                    <small v-if="formErrosApi.dataInicio" class="text-danger">
                      {{ formErrosApi.dataInicio }}
                    </small>
                    <small v-if="formErrosDataHora.dataInicio" class="text-danger">
                      {{ formErrosDataHora.dataInicio }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <validation-provider
                  name="Data Fim"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Data de Término*"
                    label-for="dataFim"
                  >
                    <b-input-group
                      class="tamanhoInputDate"
                    >
                      <b-form-input
                        id="dataFim"
                        :state="errors.length > 0 || formErrosApi.dataFim || formErrosDataHora.dataFim ? false : null"
                        v-model="form.dataFim.input"
                        type="text"
                        v-mask="'##/##/####'"
                        placeholder="00/00/0000"
                        autocomplete="off"
                        :disabled="form.dataFim.disabled"
                        @input="verificaDataHoraValida(form.dataFim.input, 'DD/MM/YYYY', 'dataFim')"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          class="form-control d-flex align-items-center"
                          :style="errors.length > 0 || formErrosApi.dataFim || formErrosDataHora.dataFim ? 'border: 1px solid red' : null"
                          button-only
                          right
                          v-model="form.dataFim.picker"
                          v-bind="labels[locale]"
                          :locale="locale"
                          :disabled="form.dataFim.disabled"
                          button-variant="custom-blue"
                          :hide-header="true"
                          clearable
                          @input="setDataPicker('dataFim')"
                          :label-help="'Use as setas do teclado para navegar'"
                          :min="form.dataFim.dataTerminoMinima"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors.length > 0" class="text-danger">
                      {{ errors[0] }}
                    </small>
                    <small v-if="formErrosApi.dataFim" class="text-danger">
                      {{ formErrosApi.dataFim }}
                    </small>
                    <small v-if="formErrosDataHora.dataFim" class="text-danger">
                      {{ formErrosDataHora.dataFim }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Fase*"
                    label-for="Select-Fase"
                  >
                    <v-select
                      id="Select-Fase"
                      v-model="form.fases.selecionado"
                      @input="setFase"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_fase"
                      label="descricao"
                      :options="form.fases.dadosSelect"
                      placeholder="Selecione a Fase"
                      :disabled="form.fases.disabled"
                    >
                      <span slot="no-options">Nenhuma fase encontrada.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1" v-if="form.notificacoes.show">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Quantidade de Notificações*"
                    label-for="Select-Notif"
                  >
                    <v-select
                      id="Select-Notif"
                      v-model="form.notificacoes.selecionado"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_notificacao"
                      label="descricao"
                      :options="form.notificacoes.dadosSelect"
                      placeholder="Selecione a quantidade de notificações"
                      :disabled="form.notificacoes.disabled"
                    >
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1" hidden>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Ano Início*"
                    label-for="Select-anoInicio"
                  >
                    <v-select
                      id="Select-anoInicio"
                      v-model="form.anoInicio.selecionado"
                      variant="custom"
                      :options="form.anoInicio.dadosSelect"
                      placeholder="Selecione o ano de Início"
                      :disabled="form.anoInicio.disabled"
                    >
                      <span slot="no-options">Nenhum ano encontrado.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4" class="mb-1" hidden>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label="Ano Fim*"
                    label-for="Select-anoFim"
                  >
                    <v-select
                      id="Select-anoFim"
                      v-model="form.anoFim.selecionado"
                      variant="custom"
                      :options="form.anoFim.dadosSelect"
                      placeholder="Selecione o ano de Término"
                      :disabled="form.anoFim.disabled"
                    >
                      <span slot="no-options">Nenhum ano encontrado.</span>
                    </v-select>
                    <small v-if="errors.length > 0" class="text-danger"
                      >Campo Obrigatório</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col md="8" class="mb-1">
              <label>Participantes</label>

              <p v-if="empresaSemParticipantes">
                <b-overlay :show="loading.participantes" rounded="lg" opacity="0.7">
                  A empresa selecionada não possui participantes vinculados.
                </b-overlay>
              </p>
              <div v-else id="participantes-tree">
                <b-overlay :show="loading.participantes" rounded="lg" opacity="0.7">
                  <vue-perfect-scrollbar
                    class="scroll-area"
                    :settings="settings"
                  >
                    <b-input-group
                      v-if="form.participantes.dados.length > 0"
                      class="container-search-input-style"
                    >
                      <b-form-input
                        class="search-input-style"
                        placeholder="Pesquisa"
                        :disabled="disabledSearchTree"
                        @input="searchTree"
                      />
                      <b-input-group-append>
                        <b-input-group-text
                          :style="disabledSearchTree ? 'background-color: #efefef;' : null"
                        >
                          <feather-icon icon="SearchIcon" />
                        </b-input-group-text>
                      </b-input-group-append>
                    </b-input-group>
                    <v-tree
                      class="ml-n2"
                      ref="arvore"
                      :maxLevel="2"
                      :data="form.participantes.dados"
                      :multiple="true"
                      :tpl="tpl"
                      @node-check="marcaParticipantes"
                      @node-click="marcaParticipantes"
                      :allowGetParentNode="true"
                      :halfcheck="false"
                    />
                  </vue-perfect-scrollbar>
                </b-overlay>
              </div>
            </b-col>
            <b-col md="4" class="mt-2">
              <div>Líder, RH e Saúde</div>
              <div class="mb-2 customNumber">
                {{ dados.numeroNaoTrabalhadores }}
              </div>
              <div>Trabalhadores</div>
              <div class="mb-2 customNumber">
                {{ dados.numeroTrabalhadores }}
              </div>
              <div>
                <strong>Total de participantes selecionados</strong>
              </div>
              <div class="customNumber">
                {{ dados.numeroParticipantes }}
              </div>
            </b-col>
          </b-row>

          <b-row class="botoes">
            <b-col md="12" align="center">
              <b-button
                v-if="form.status == 'Rascunho'"
                type="reset"
                variant="outline-secondary"
                class="mr-1"
                @click.prevent="direcionarListagem"
              >
                Desfazer
              </b-button>
              <b-button
                type="submit"
                variant="custom"
                class="mr-1"
                @click.prevent="submitForm(true)"
                v-show="form.status == 'Rascunho'"
                :disabled="
                  empresaSemParticipantes ||
                  form.status != 'Rascunho' ||
                  bloqueiaSalvar
                "
              >
                Salvar rascunho
              </b-button>
              <b-button
                type="submit"
                variant="custom"
                class="mr-1"
                @click.prevent="submitForm(false)"
                :disabled="
                  empresaSemParticipantes ||
                  bloqueiaSalvar ||
                  !['Rascunho', 'Agendado', 'Em Andamento'].includes(
                    form.status
                  )
                "
              >
                <span v-if="form.status == 'Rascunho'">Iniciar ciclo</span>
                <span v-if="form.status != 'Rascunho'">Salvar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { VTree, VSelectTree } from "vue-tree-halower";
import "@core/scss/vue/libs/halower-tree.min.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, between, min, max, length, email } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { MODULOS } from "@/custom-enum/modulos-enum.js";
import moment from 'moment';
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormDatepicker,
  BInputGroupText
} from "bootstrap-vue";
import { modalGenericModel } from "@/libs/sweetalerts";

export default {
  components: {
    BFormInput,
    BRow,
    BFormCheckbox,
    BBadge,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    required,
    between,
    min,
    max,
    length,
    email,
    VSelectTree,
    VTree,
    VuePerfectScrollbar,
    BFormDatepicker,
    BInputGroupText
  },

  props: {
    Ciclo: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      rota: "",
      timerSearchTree: null,
      disabledSearchTree: false,
      dataHoje: moment().format('YYYY-MM-DD'),
      checktrabalhadores: false,
      checkNtrabalhadores: false,
      empresaSemParticipantes: false,
      cicloCarregado: [],
      configDataFim: {
        wrap: false,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "d/m/Y",
        minDate: new Date().setHours(0, 0, 0, 0),
        locale: Portuguese,
      },
      configDataIni: {
        wrap: false,
        altFormat: "d/m/Y",
        altInput: true,
        minDate: new Date().setHours(0, 0, 0, 0),
        dateFormat: "d/m/Y",
        locale: Portuguese,
      },
      settings: {
        maxScrollbarLength: 10,
        wheelSpeed: 0.3,
        wheelPropagation: false,
      },
      bloqueiaSalvar: false,
      form: {
        participantes: {
          dados: [],
          selecionados: [],
        },
        grupo: {
          selecionado: "",
          idGrupo: null,
          disabled: false,
          dadosSelect: [],
          disabledButton: true,
        },
        filiais: {
          selecionado: "",
          disabled: true,
          dadosSelect: [],
          disabledButton: true,
          idEmpresa: null,
        },
        modulos: {
          selecionado: "",
          disabled: true,
          dadosSelect: [],
        },
        questionario: {
          selecionado: "",
          disabled: true,
          dadosSelect: [],
        },
        fases: {
          selecionado: null,
          disabled: true,
          dadosSelect: [],
        },
        notificacoes: {
          selecionado: "",
          disabled: false,
          dadosSelect: ["1", "2", "3", "4"],
          show: false,
        },
        dataInicio: {
          input: null,
          picker: null,
          disabled: false
        },
        dataFim: {
          input: null,
          picker: null,
          disabled: false,
          dataTerminoMinima: moment().format('YYYY-MM-DD')
        },
        anoInicio: {
          selecionado: "",
          disabled: false,
          dadosSelect: [],
        },
        anoFim: {
          selecionado: "",
          disabled: false,
          dadosSelect: [],
        },
        status: "Rascunho",
      },
      loading: {
        pagina: true,
        participantes: false,
      },
      informacoesParaEnviar: {},
      dados: {
        numeroParticipantes: 0,
        numeroTrabalhadores: 0,
        numeroNaoTrabalhadores: 0,
      },
      formErrosApi: {
        nome: null,
        idEmpresa: null,
        idQuestionario: null,
        dataInicio: null,
        dataFim: null,
        horaInicio:  null,
        horaTermino: null,
        descricao: null
      },
      formErrosDataHora: {
        dataInicio: null,
        dataFim: null,
        horaInicio:  null,
        horaTermino: null
      },
      locale: 'pt-BR',
      labels:{
        'pt-BR': {
          labelHours: 'Horas',
          labelMinutes: 'Minutos',
          labelSeconds: 'Segundos',
          labelAmpm: 'AM / PM',
          labelAm: 'AM',
          labelPm: 'PM',
          labelIncrement: 'Aumentar',
          labelDecrement: 'Reduzir',
          labelSelected: 'Hora Selecionada',
          labelNoTimeSelected: 'Nenhum horário selecionado',
          labelCloseButton: 'fechar',
        }
      }
    };
  },
  created() {
    this.rota = this.$route.params.rota;
  },
  mounted() {
    this.populaGrupos();
    this.anosProximos();
    this.disabledFormCriacao(true);
  },
  methods: {

    searchTree (searchWord) {
      clearTimeout(this.timerSearchTree);
      this.timerSearchTree = setTimeout(async () => {
        this.loading.participantes = true
        this.disabledSearchTree = true
        let escapedSpecialChars = new RegExp(searchWord.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&"), 'i')
        if(!this.verificaExistenciaColaboradorPorString(escapedSpecialChars)){
          modalGenericModel(
            'Colaborador não encontrado',
            'Não existem colaboradores com esse nome cadastrados no sistema',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          )
        }
        await this.waitForSearchNodes(escapedSpecialChars);
        this.loading.participantes = false
        this.disabledSearchTree = false
      }, 800);
    },

    verificaExistenciaColaboradorPorString(stringTratada){
      for(let node of this.form.participantes.dados){
        if(stringTratada.test(node.title)) {
          return true;
        }
        for(let nodeChild of node.children){
          if(stringTratada.test(nodeChild.nome)) {
            return true;
          }
        }
      }
      return false;
    },

    async waitForSearchNodes(searchWord) {
      return new Promise((resolve) => {
        this.$nextTick(async () => {
          this.$refs.arvore.searchNodes(node => searchWord.test(node.title));
          setTimeout(function() {
            resolve();
          }, 1500);
        });
      });
    },

    verificaDataHoraValida(dataOuHora, tipo, stringErroApi){
      this.formErrosDataHora[stringErroApi] = null;
      clearTimeout(this.countTimerData);
      this.countTimerData = setTimeout(() => {
        if(moment(dataOuHora, tipo, true).isValid() === false){
          this.formErrosDataHora[stringErroApi] = 'Campo preenchido incorretamente';
        }
      }, 200);
    },

    setDataPicker(tipoData){
      if(tipoData === 'dataInicio'){
        this.form.dataInicio.input = moment(this.form.dataInicio.picker).format('DD/MM/YYYY')
      }else if(tipoData === 'dataFim'){
        this.form.dataFim.input = moment(this.form.dataFim.picker).format('DD/MM/YYYY')
      }
    },

    direcionarListagem() {
      let nomeRota = "";
      if(!this.rota){
        nomeRota = "ciclos";
      }else{
        nomeRota = this.rota == 1 ? 'ciclos-saude-mental' : 'ciclos-planeja-saude';
      }
      this.$router.push({
        name: nomeRota,
      });
    },

    anosProximos(){
      let atual = new Date().getFullYear();
      let proximo = new Date().getFullYear() + 1;
      this.form.anoInicio.selecionado = atual;
      this.form.anoFim.selecionado = atual;
      this.form.anoInicio.dadosSelect = [atual, proximo];
      this.form.anoFim.dadosSelect = [atual, proximo];
    },

    selecionaEmpresa(){
      if (this.form.grupo.selecionado) {
        this.form.filiais.disabledButton = false;
      } else {
        this.form.filiais.disabledButton = true;
        this.limpaForm();
      }
    },

    setGrupo(){
      if (this.form.grupo.selecionado) {
        this.form.modulos.dadosSelect = [];
        this.populaEmpresasDoGrupo(
          this.form.grupo.selecionado.matriz,
          this.form.grupo.selecionado.filiais
        );
        this.populaModulosDoGrupo(this.form.grupo.selecionado.modulos);
      } else {
        this.limpaForm();
      }
    },

    setModulo(event){
      this.form.questionario.selecionado = "";
      this.form.participantes.dados = [];
      this.form.fases.selecionado = "";
      if (!event) {
        return;
      }
      this.disabledFormCriacao(false);
      this.populaQuestionariosDoModulo(event.questionarios);
      if (event.id_modulo == MODULOS.PLANEJA_SAUDE) {
        this.form.notificacoes.selecionado = 0;
        this.form.notificacoes.show = false;
      } else {
        this.form.notificacoes.show = false;
      }
      this.form.questionario.disabled = false;
    },

    setEmpresa(){
      this.form.modulos.disabled = false;
    },

    buscaFasesDisponiveis($empresa, $modulo, $questionario){
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.FasesDisponiveis,
        {
          "{idEmpresa}": $empresa,
          "{idModulo}": $modulo,
          "{idQuestionario}": $questionario
        }
      );
      this.$http
        .get(rota)
        .then((res) => {
          if (res.status == 200) {
            this.form.fases.dadosSelect = res.data;
          }
        })
        .catch((error) => {
          this.ErroAPI(error);
        });
    },

    participantesSelecionados(){
      this.form.participantes.selecionados = [];
      let participantesSelecionados = this.$refs.arvore.getCheckedNodes();
      participantesSelecionados.map((item) => {
        if (item.id_colaborador) {
          this.form.participantes.selecionados.push(item.id_colaborador);
        }
      });
    },

    getClasseSituacao(sit){
      let classes = {
          agendado: "warning",
          cancelado: "danger",
          concluido: "primary",
          "em-andamento": "success",
          rascunho: "secondary",
        },
        situacao = this.$helpers.removeAcentos(
          sit.toLowerCase().replaceAll(" ", "-")
        );
      return classes[situacao];
    },

    atualizaResumo(){
      let participantes = this.$refs.arvore.getCheckedNodes();
      this.dados.numeroParticipantes = 0;
      this.dados.numeroTrabalhadores = 0;
      this.dados.numeroNaoTrabalhadores = 0;
      participantes.map((item) => {
        if (item.isTrabalhador === false) {
          this.dados.numeroNaoTrabalhadores++;
        } else if (item.isTrabalhador === true) {
          this.dados.numeroTrabalhadores++;
        }
      });
      this.dados.numeroParticipantes =
        this.dados.numeroNaoTrabalhadores + this.dados.numeroTrabalhadores;
    },

    disabledFormCriacao(disabled){
      this.form.grupo.disabledButton = disabled;
      this.form.grupo.disabled = !disabled;
      this.form.filiais.disabledButton = disabled;
      this.form.filiais.disabled = disabled;
      this.form.modulos.disabled = disabled;
      this.form.questionario.disabled = disabled;
      this.form.dataInicio.disabled = disabled;
      this.form.dataFim.disabled = disabled;
      this.form.fases.disabled = disabled;
      this.form.notificacoes.disabled = disabled;
    },

    selecionaGrupo() {
      if (this.form.grupo.selecionado) {
        this.form.grupo.disabledButton = false;
        this.form.modulos.dadosSelect = [];
      } else {
        this.form.filiais.disabledButton = true;
        this.form.grupo.disabledButton = true;

        this.limpaForm();
      }
    },

    setFase(event){
      this.form.participantes.dados = [];
      this.dados.numeroNaoTrabalhadores = 0;
      this.dados.numeroTrabalhadores = 0;
      this.dados.numeroParticipantes = 0;
      this.empresaSemParticipantes = false;
      if (event) {
        this.populaParticipantes(
          this.form.filiais.selecionado.id_empresa,
          this.form.modulos.selecionado.id_modulo,
          null,
          this.form.fases.selecionado
        );
      }
    },

    setQuestionario(event){
      this.dados.questionarioDoModuloSelecionado = event;
      this.form.fases.selecionado = "";

      if(event){
        this.buscaFasesDisponiveis(
          this.form.filiais.selecionado.id_empresa,
          this.form.modulos.selecionado.id_modulo,
          this.form.questionario.selecionado.id_questionario
        );
      }
    },

    populaGrupos(){
      if(!this.rota){
        this.$http.get(this.$api.GruposBusca).then((res) => {
          this.form.grupo.dadosSelect = res.data.data;
          this.loading.pagina = false;
        });
      }else{
        let rota = this.$helpers.preparaRotaComParametros(
          this.$api.GruposBuscaPorModulo,
          "{idModulo}",
          this.rota
        );
        this.$http.get(rota).then((res) => {
          this.form.grupo.dadosSelect = res.data.data;
          this.loading.pagina = false;
        });
      }
      
    },

    populaEmpresasDoGrupo(matriz, filiais){
      this.form.filiais.disabled = true;
      matriz.nomeDescricao =
        matriz.nome_fantasia + " - " + this.$helpers.formataCnpj(matriz.cnpj);
      this.form.filiais.dadosSelect = [];
      this.form.filiais.dadosSelect.push(matriz);
      filiais.forEach((filial) => {
        filial.nomeDescricao =
          filial.empresa.nome_fantasia + " - " + this.$helpers.formataCnpj(filial.empresa.cnpj);
        this.form.filiais.dadosSelect.push(filial);
      });

      if (this.form.filiais.dadosSelect.length > 0) {
        if (this.form.filiais.dadosSelect.length == 1) {
          this.form.filiais.selecionado = this.form.filiais.dadosSelect[0];
          this.form.filiais.disabledButton = false;
        }
        this.form.filiais.disabled = false;
      }
    },

    populaModulosDoGrupo(modulos){
      if (!this.rota) {
        modulos.forEach((modulo) => {
          if (modulo.descricao === 'Saúde Mental' || modulo.descricao === 'Planeja Saúde') {
            this.form.modulos.dadosSelect.push(modulo);
          }
        });
      } else {
        const moduloFiltrado = modulos.filter((modulo) => modulo.id_modulo === this.rota);
        this.form.modulos.dadosSelect = moduloFiltrado;
      }
      this.form.modulos.disabled = false;
    },

    populaQuestionariosDoModulo(questionarios){
      this.form.questionario.selecionado = "";
      this.form.questionario.dadosSelect = Object.values(questionarios);
    },

    limpaForm(){
      this.$refs.simpleRules.reset();
      this.form.filiais.selecionado = "";
      this.form.grupo.selecionado = "";
      this.form.modulos.selecionado = "";
      this.form.questionario.selecionado = "";

      this.form.notificacoes.selecionado = "";

      this.form.filiais.dadosSelect = [];
      this.form.modulos.dadosSelect = [];
      this.form.questionario.dadosSelect = [];

      this.form.participantes.dados = [];
      this.form.dataInicio.input = "";
      this.form.dataFim.input = "";
      this.dados.numeroNaoTrabalhadores = 0;
      this.dados.numeroTrabalhadores = 0;
      this.dados.numeroParticipantes = 0;
      this.empresaSemParticipantes = false;

      this.disabledFormCriacao(true);
    },

    FormataDadosFormulario(rascunho){
      this.participantesSelecionados();
      let situacao = rascunho ? "Rascunho" : "";
      return {
        inicio: this.form.dataInicio.input,
        termino: this.form.dataFim.input,
        quantidade_notificacao: 1,
        id_empresa:
          this.form.filiais.selecionado.id_empresa ??
          this.cicloCarregado.id_empresa,
        id_fase: this.form.fases.selecionado,
        id_modulo:
          this.form.modulos.selecionado.id_modulo ??
          this.cicloCarregado.questionario.id_modulo,
        id_questionario:
          this.form.questionario.selecionado.id_questionario ??
          this.cicloCarregado.id_questionario,
        ano_inicio: this.form.dataInicio.input.split("/")[2],
        ano_termino: this.form.dataFim.input.split("/")[2],
        colaboradores: this.form.participantes.selecionados,
        situacao: situacao,
      };
    },

    SalvarDados(rascunho){
      this.loading.pagina = true;
      let form = this.FormataDadosFormulario(rascunho);
      let rota = this.$api.CiclosInsert;

      this.$http
        .post(rota, form)
        .then((res) => {
          if (res.status == 201) {
            modalGenericModel(
              'Sucesso!',
              'O ciclo foi salvo com sucesso!',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
            this.loading.pagina = false;
            this.limpaForm();
            this.$emit("closeModal");
            this.direcionarListagem();
          }
        }).catch((error) => {
          this.ErroAPI(error);
        });
    },

    submitForm(rascunho){
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.SalvarDados(rascunho);
        }
      });
    },

    handleModalErro(erros){
      let errosDisplay = ''

      for(let erro of erros){
        errosDisplay += '<br><div>'+erro+'</div>'
      }

      errosDisplay = errosDisplay.substring(4)

      modalGenericModel(
        'Erro ao Salvar Dados',
        errosDisplay,
        require('@/assets/custom-icons/cora-icons/error.png'),
        'Ok'
      )
    },

    ErroAPI(error){
      let erros = ['Erro de conexão com o servidor, tente novamente mais tarde.'];

      if(error?.response?.data){
        erros = Object.values(error.response.data.errors);
      }

      this.handleModalErro(erros);
      this.loading.pagina = false;
    },

    populaParticipantes(
      idEmpresa,
      idModulo = null,
      idCiclo = null,
      idFase = null,
      idQuestionario = null,
    ){
      this.loading.pagina = true;
      this.form.participantes.dados = [];
      this.form.participantes.selecionados = [];

      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.CiclosParticipantesDaEmpresa,
        {
          "{idEmpresa}": idEmpresa,
        }
      );

      this.$http
        .get(rota, {
          params: this.lidarComParametrosBuscaCiclosParticipantes(
            idModulo,
            idCiclo,
            idFase,
            idQuestionario
          ),
        })
        .then((response) => {
          if (response.status != 204){
            this.empresaSemParticipantes = false;
            let retorno = response.data.data;
            let colaboradoresAgrupados = this.agruparPorColaborador(retorno[""]);
            let { trabalhadores, naoTrabalhadores } = this.separarGruposPorColaborador(colaboradoresAgrupados);
            this.montaObjetoParticipantes(trabalhadores, naoTrabalhadores);
          } else {
            this.empresaSemParticipantes = true;
            this.loading.pagina = false;
          }
        });
    },

    agruparPorColaborador(data) {
      const mapaColaboradores = {};
      data.forEach((item) => {
        const id = item.id_colaborador;
        const perfil = item.perfil;
        if (!mapaColaboradores[id]) {
          mapaColaboradores[id] = {
            id_colaborador: id,
            nome: item.nome,
            cpf: this.formatarCPF(item.cpf),
            perfis: [],
            ativo: item.ativo
          };
        }
        if (perfil && !mapaColaboradores[id].perfis.includes(perfil)) {
          mapaColaboradores[id].perfis.push(perfil);
        }
      });
      return Object.values(mapaColaboradores);
    },

    formatarCPF(cpf) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },

    montaObjetoParticipantes(trabalhadores, naoTrabalhadores) {
      const promiseTrab = trabalhadores.children.map(async (item) => {
        item.title = item.nome;
        item.chkDisabled = !item.ativo;
        item.selDisabled = !item.ativo;
        item.checked = false;
        item.isTrabalhador = true;
      });
      const promiseNTrab = naoTrabalhadores.children.map(async (item) => {
        item.title = item.nome;
        item.chkDisabled = !item.ativo;
        item.selDisabled = !item.ativo;
        item.checked = false;
        item.isTrabalhador = false;
      });
      Promise.all([Promise.resolve(promiseTrab), Promise.resolve(promiseNTrab)]).then(() => {
        this.form.participantes.dados.push(trabalhadores);
        this.form.participantes.dados.push(naoTrabalhadores);
        this.atualizaResumo();
        this.verificaTodosMarcados(trabalhadores);
        this.verificaTodosMarcados(naoTrabalhadores);

        this.loading.pagina = false;
      });
    },

    separarGruposPorColaborador(colaboradoresAgrupados) {
      const trabalhadores = {
        title: "Trabalhadores",
        expanded: false,
        children: [],
        id: "sim",
        nocheck: true,
      };
      const naoTrabalhadores = {
        title: "Líderes, RH e Saúde",
        expanded: false,
        children: [],
        id: "nao",
        nocheck: true,
      };
      colaboradoresAgrupados.forEach((colaborador) => {
        const perfisStr = colaborador.perfis.join(", ");
        const inativadoStr = !colaborador.ativo ? " - Inativo" : "";
        const novoItem = {
          id_colaborador: colaborador.id_colaborador,
          nome: `${colaborador.nome} (${perfisStr}) - ${colaborador.cpf}${inativadoStr}`,
          ativo: colaborador.ativo
        };

        if (colaborador.perfis.includes("Trabalhador")) {
          trabalhadores.children.push(novoItem);
        } else {
          naoTrabalhadores.children.push(novoItem);
        }
      });
      return { trabalhadores, naoTrabalhadores };
    },

    lidarComParametrosBuscaCiclosParticipantes(
      idModulo = null,
      idCiclo = null,
      idFase = null,
      idQuestionario = null
    ){

      return {
        id_ciclo: idCiclo ?? this.Ciclo.id_ciclo ?? null,
        id_modulo: idModulo ?? this.form.modulos.selecionado.id_modulo,
        id_fase: idFase ?? this.form.fases.selecionado,
        id_questionario: idQuestionario ?? this.form.questionario.selecionado.id_questionario
      };
    },

    marcaParticipantes(node) {
      if (node.id == "sim" || node.id == "nao") {
        let check =
          node.id == "sim" ? this.checktrabalhadores : this.checkNtrabalhadores;
        this.loading.participantes = true;
        Promise.all(
          node.children.map((item) => {
            if (item.ativo) {
              item.checked = check;
            }
          })
        ).then(() => {
          this.atualizaResumo();
          this.loading.participantes = false;
        });
      } else {
        let pai = node.parent();
        this.verificaTodosMarcados(pai);
        this.atualizaResumo();
      }
    },

    verificaTodosMarcados(node) {
      let checktodos = false;
      if (
        !node.children.find((element) => !element.checked) &&
        node.children.length > 0
      ) {
        checktodos = true;
      }
      if (node.id == "sim") {
        this.checktrabalhadores = checktodos;
      } else {
        this.checkNtrabalhadores = checktodos;
      }
    },

    tpl(...args) {
      let { 0: node } = args;
      let titleClass = node.selected
        ? "node-title node-selected"
        : "node-title";
      let ret;
      if(
          node.children == null &&
          node?.ativo === false
        ){
        titleClass += ' node-title-inativo'
      }
      if (node.id == "sim") {
        ret = (
          <span>
            <input
              type="checkbox"
              class="checkNodes"
              ref={node.id}
              v-model={this.checktrabalhadores}
              disabled={
                !["Rascunho", "Agendado", "Em Andamento"].includes(
                  this.form.status
                )
              }
              onchange={() => this.marcaParticipantes(node)}
            />

            <span class={titleClass}>{node.title}</span>
          </span>
        );
      } else if (node.id == "nao") {
        ret = (
          <span>
            <input
              type="checkbox"
              class="checkNodes"
              ref={node.id}
              v-model={this.checkNtrabalhadores}
              disabled={
                !["Rascunho", "Agendado", "Em Andamento"].includes(
                  this.form.status
                )
              }
              onchange={() => this.marcaParticipantes(node)}
            />

            <span class={titleClass}>{node.title}</span>
          </span>
        );
      } else {
        ret = (
          <span>
            <span class={titleClass}>{node.title}</span>
          </span>
        );
      }

      return ret;
    },
  },
};
</script>

<style lang="scss" scoped>
#participantes-tree {
  width: 100%;
  height: 300px;
  border: 1px solid;
}
#status {
  width: 100px;
  height: 40px;
  background: gray;
  border-radius: 100px / 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#status p {
  margin: 10px;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 295px;
}
input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 1px solid #555555;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: 1px solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
li {
  list-style-type: none;
}
.customNumber {
  font-weight: 700;
  font-size: 20px;
  color: #7030a0;
}
.tamanhoInputDate{
  min-width: 190px !important;
}
.checkNodes {
  margin-right: 7px;
}
.container-search-input-style {
  margin: 10px;
  width: 220px;
}
.search-input-style {
  border-right: none;
}
.node-title-inativo {
  color: #82868b;
}
</style>
